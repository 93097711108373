<template>
  <div class="container-fluid">
    <div class="row" v-for="(part, index) in survey_parts"
        :key="index">

        <h3>
            {{part.title}} :
        </h3>


        <template v-for="(group, index) in part.marketing_survey_questions_groups" :key="index">
    
        <!-- <h4>
            {{group.name}}
        </h4> -->
        <template v-for="(question, index) in group.marketing_survey_questions" :key="index">
            
                
      <div v-if="question && question.pieChart"
        class="col-md-6 col-sm-12"
      >





        <div class="card">
          <div  class="card-body">
            <p class="fw-bold">{{ group.name }} </p>

            <apexchart
              class="apex-charts"
              height="320"
              type="pie"
              dir="ltr"
              :series="question.pieChart.series"
              :options="question.pieChart.chartOptions"
            ></apexchart>
          </div>
        </div>
      </div>
            
        </template>
    
         </template>

    </div>
  </div>
</template>

<script>
import { pieChart } from "../../chartData";
export default {
  data() {
    return {
      survey_parts: [],
      pieChart: pieChart,
    };
  },

  components: {},

  methods: {
    getReandomHex() {
      let arr = [];
      let suggestions = "0123456789ABCDEF";
      for (let i = 0; i < 6; i++)
        arr.push(suggestions[Math.floor(Math.random() * suggestions.length)]);
      return "#" + arr.join("");
    },

    getMarketingSurveyQuestionWithChartsBySurveyId() {
      this.http
        .post("marketing-survey-parts/by-survey-id", {
          marketing_survey_id: this.$route.params?.id,
        })
        .then((res) => {
          this.survey_parts = res.data.filter(part =>
    part?.marketing_survey_questions_groups.some(group =>
        group?.marketing_survey_questions.some(question => {
            const typeCode = question?.marketing_survey_question_type?.code;
            return typeCode == 'check_box' || typeCode == 'multiple_choices' || typeCode == 'radio'||typeCode == 'select';
        })
    )
);

          console.log("before ", res.data);
          this.survey_parts.forEach((part) => {
            part.marketing_survey_questions_groups.forEach((group) => {
              group.marketing_survey_questions.map((item) => {

                  if (item.marketing_survey_question_type.code == "check_box") {
                    item.pieChart = JSON.parse(JSON.stringify(this.pieChart));
                    item.pieChart.series = item.series;
                    item.pieChart.chartOptions.labels = item.options;

                    item.pieChart.chartOptions.colors = item.options.map(() =>
                      this.getReandomHex()
                    );
                  } else if(item.marketing_survey_question_type.code=='radio' ||item.marketing_survey_question_type.code=='multiple_choices'||item.marketing_survey_question_type.code=='select') {
                    item.pieChart = JSON.parse(JSON.stringify(this.pieChart));
                    console.log("step 89 : item label options", item);
                    item.pieChart.series = item.series;
                    item.pieChart.chartOptions.labels = item.options;
                    item.pieChart.chartOptions.title.text = item?.question;
                    item.pieChart.chartOptions.colors = item.options.map(() =>
                      this.getReandomHex()
                    );
                  }
                  return item;

              }) .filter((i) => i != undefined);
            });
          });

          console.log("after :", this.survey_parts);
         
          
        });
    },
  },

  mounted() {
    this.getMarketingSurveyQuestionWithChartsBySurveyId();
  },
};
</script>

<style></style>