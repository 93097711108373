<script>
  import appConfig from "@/app.config";

  export default {
    page: {
      meta: [
        {
          name: "description",
          content: appConfig.description,
        },
      ],
    },
    data() {
      return {
        users: [],
        Users: {},
        marketing_team_members: [],
        page: 1,
        limit: 20,
        tot_pages: 0,
        searchUsers: null,
        SearchMode: false,
        emps: [],
        new_roles: [],
        newRoles: [{}],
        addRoles: [{}],
        branches: [],
        user_info: {},
        new_pass: "",
        users_switch: "",
        user_status: 0,
        data: {},
        roles: [],

        // appprices:[]
      };
    },
    methods: {
      editUser() {
        console.log(this.user_info);
        this.http
          .put("marketing-team-members/", this.user_info.id, {
            new_roles: this.newRoles,
            name: this.user_info.name,
            email: this.user_info.email,
            branch: this.user_info.emp.branch.name,
          })
          .then(() => {
            this.get(this.page);
            console.log(this.newRoles);
          });
      },
      addUsers() {
        this.data.marketing_survey_id = this.$route?.params?.id;
        this.http.post("marketing-survey-team-members", this.data,null,true).then((res) => {
          if (res.status) {
          this.popup.alert({ title: '', msg: 'popups.success' })
          document.querySelector('#addModal .close-modal-btn').click()

            this.data = {};
            this.get(this.page);
            
          }
        });
      },
      search() {
        this.SearchMode = true;
        console.log(this.searchUsers);
        this.tot_pages = 0;
        this.http
          .post("marketing-survey-team-members/by-survey-id/search", {
            limit: this.limit,
            page: this.page,
            search: this.searchUsers,
            col: "name",
            marketing_survey_id: this.$route?.params?.id,
          }, null, true)
          .then((res) => {
            this.users = res.data;
          });
      },
      cancelSearchMode() {
        this.searchUsers = "";
        this.SearchMode = false;
        this.get(this.page);
      },
      getUsers() {
        this.http
          .post("marketing-survey-team-members/by-survey-id", {
            id: this.$route.params?.id,
          }, null, true)
          .then((res) => {
            this.users = res.data;
          });
      },
      getMarketingTeamMembers() {
        this.http.get("marketing-team-members",null, null, true).then((res) => {
          this.marketing_team_members = res.data;
        });
      },
      deleteUser(user) {
        var data = {
          title: "popups.deleteuser",
          msg: "popups.deletemsg",
          buttons: [
            {
              text: "Delete",
              closer: true,
              color: "danger",
              handler: () => {},
            },
            {
              text: "Cancel",
              closer: true,
              color: "light",
              handler: () => {
                console.log("Like Clicked");
                
              },
            },
          ],
        };
        this.popup.confirm(data).then(() => {
          console.log(user);
          this.http.delete("marketing-survey-team-members", user.id).then(() => {
            this.get(this.page);
            this.getMarketingTeamMembers()
          });
        });
      },
      userInfo(user) {
        this.user_info = user;
        console.log(this.user_info);
      },
      editPass() {
        console.log(this.user_info.id);
        this.http.post("marketing-team-members/update-password", {
          id: this.user_info.id,
          password: this.new_pass,
        }, null, true);
      },

      get(page) {
        console.log(this.userstable);
        console.log({ route_param: this.$route.params?.id });
        this.http
          .post("marketing-survey-team-members/by-survey-id/pagination", {
            limit: this.limit,
            page: page,
            marketing_survey_id: this.$route.params?.id,
          }, null, true)
          .then((res) => {
            this.page = page;
            this.tot_pages = Math.ceil(res.tot / this.limit);
            this.users = res.data;
          });
      },
    },
    created() {
      this.get(1);
      this.getMarketingTeamMembers();
    },
  };
</script>

<template>
  <div>
    <div class="row">
      <div>
        <div class="row align-items-center">
          <div class="col-4"></div>

          <div class="col-8">
            <div
              class="search-box chat-search-box d-flex align-items-center"
              style="width: 250px; margin-inline-start: auto"
            >
              <div class="position-relative mx-3 d-flex align-items-center">
                <input
                  @keyup="search()"
                  type="text"
                  class="form-control fa-lg text-light"
                  :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
                  :placeholder="$t('users.searchplaceholder')"
                  v-model="searchUsers"
                  style="background-color: #2a3042 !important"
                />
                <i class="bx bx-search-alt search-icon text-light"></i>
                <button
                  @click="cancelSearchMode()"
                  v-if="SearchMode"
                  class="btn-close text-light"
                  style="position: absolute; top: 12px; left: 250px"
                ></button>
              </div>
              <button
                type="button"
                class="btn btn-light float-end"
                data-bs-toggle="modal"
                data-bs-target="#addModal"
              >
                <span
                  class="bx bxs-plus-circle float-end fa-2x"
                  style="color: #2a3042 !important"
                ></span>
              </button>
            </div>
          </div>
        </div>
        <!-- end row -->

        <hr class="mb-4" />

        <div class="row">
          <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table">
            <thead>
              <tr
                class="text-light text-center"
                style="background-color: #2a3042 !important"
              >
                <th scope="col">{{ $t("users.userstable.id") }}</th>
                <th scope="col">
                  {{ $t("marketing.team_members.table.name") }}
                </th>
                <th scope="col">
                  {{ $t("marketing.team_members.table.tel") }}
                </th>
                <th scope="col">
                  {{ $t("marketing.team_members.table.email") }}
                </th>
                <th scope="col">
                  {{ $t("marketing.team_members.table.rate") }}
                </th>
                <th scope="col">
                  {{ $t("marketing.team_members.table.birthdate") }}
                </th>
                <th scope="col">{{ $t("users.userstable.created") }}</th>
                <th scope="col">{{ $t("users.userstable.updated") }}</th>
                <th scope="col">{{ $t("users.userstable.operations") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="text-center"
                v-for="(user, index) in users"
                :key="user"
              >
                <td>{{ index + 1 }}</td>
                <td>{{ user?.marketing_team_member?.name }}</td>
                <td>{{ user?.marketing_team_member?.tel }}</td>
                <td>{{ user?.marketing_team_member?.email }}</td>
                <td>
                  {{
                    user?.marketing_team_member?.rate
                      ? user?.marketing_team_member?.rate
                      : "--"
                  }}
                </td>
                <td>
                  {{ user?.marketing_team_member?.birthdate?.split("T")[0] }}
                </td>
                <td>{{ user?.created.split("T")[0] }}</td>
                <td>{{ user?.updated.split("T")[0] }}</td>
                <td class="d-flex justify-content-end">
                  <button
                    @click="getSwitch(user, 1)"
                    v-if="user.status == 0"
                    class="btn btn-primary btn-sm me-2"
                  >
                    {{ $t("popups.enable") }}
                  </button>
                  <button
                    type="button"
                    @click="getSwitch(user, 0)"
                    v-if="user.status == 1"
                    class="btn btn-secondary btn-sm me-2"
                  >
                    {{ $t("popups.disable") }}
                  </button>
                  <a
                    class="btn btn-danger btn-sm me-2"
                    :class="{}"
                    href="javascript:void(0)"
                    role="button"
                    @click="deleteUser(user)"
                    >{{ $t("popups.delete") }}</a
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <!--Start Add Modal-->
    <div
      class="modal fade"
      id="addModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <form @submit.prevent="addUsers()">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                {{ $t("popups.adduser") }}
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                style="margin: 0"
              ></button>
            </div>

            <div class="modal-body">
              <label for="user">{{ $t("popups.add_team") }}</label>
              <select
                v-model="data.marketing_team_member_id"
                class="form-select mb-2"
                id="user_role"
              >
                <option
                  v-for="member in marketing_team_members"
                  :key="member.id"
                  :value="member.id"
                >
                  {{ member?.name }}
                </option>
              </select>
            </div>
            <div
              class="modal-footer"
              :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
            >
              <button type="submit" class="btn btn-primary">
                {{ $t("popups.add") }}
              </button>
              <button
                data-bs-dismiss="modal"
                type="button"
                class="btn close-modal-btn btn-danger"
              >
                {{ $t("popups.cancel") }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!--End Add Modal-->
  </div>
</template>
<!-- marketing-survey-team-members -->
