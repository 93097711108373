<template>
  <div class="container-fluid">
        

       


                
      <div 
        class="col-md-12 col-sm-12"
      >
        <div class="card">
          <div  class="card-body">
            <!-- <p class="fw-bold">{{ group.name }} </p> -->

            <apexchart
            v-if="this.districts?.length !=0"
              class="apex-charts"
              height="320"
              type="bar"
              dir="ltr"
              :series="barChart.series"
              :options="barChart.chartOptions"
              @click="handleChartClick"
            ></apexchart>
          </div>
        </div>
      </div>
            
    

  </div>
</template>

<script>
import { barChart } from "../../chartData";
export default {
  data() {
    return {
      districts: [],
      barChart: barChart,
    };
  },

  components: {},

  methods: {
    handleChartClick(event, chartContext,seriesIndex, config){
      console.log({event, chartContext, seriesIndex,config});
      console.log("selectedIndex  :",seriesIndex.dataPointIndex);
      console.log("seriesIndex  :",seriesIndex.config);
      // this.barChart.series
      
      
    },
    getReandomHex() {
      let arr = [];
      let suggestions = "0123456789ABCDEF";
      for (let i = 0; i < 6; i++)
        arr.push(suggestions[Math.floor(Math.random() * suggestions.length)]);
      return "#" + arr.join("");
    },

    
    getDistrictsChart(){
      this.http.get('marketing-districts/survey-processes-chart',{marketing_survey_id:this.$route.params.id}).then((res)=>{
        if(res.status){
          this.districts= res.data
         
          this.barChart.series = [{data:this.districts.map(district => district?.survey_processes_count),name:this.$t('marketing.team_member.surveys_count'),id:this.districts.map(district => district?.id)}];          
      this.barChart.chartOptions.xaxis.categories = this.districts.map(district => district?.name)
      //     this.barChart.series.push({data:5 ,name: this.$t('marketing.team_member.surveys_count'), id:243});          
      // this.barChart.chartOptions.xaxis.categories.push('حدى')

console.log("step 64  :",this.barChart);

        }
      })
    }
  },

  mounted() {
    this.getDistrictsChart()

  },
};
</script>

<style></style>