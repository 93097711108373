<script>
import appConfig from "@/app.config";
import Swal from "sweetalert2";

export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {},
  data() {
    return {
      marketing_survey_parts: [{
        marketing_survey_id:this.$route.params.id,
        title:"",
        marketing_survey_questions_groups: [
          {
            name: "",
            unit: "",
            alignment: "v",
            multiple_answers: true,
            answer_count: 0,
            marketing_survey_questions: [
              {
                question: "",
                marketing_survey_id:this.$route.params.id,
                marketing_survey_question_options: [
                  {
                    option: "",
                  },
                ],
                marketing_survey_question_type: {
                  code: "number",
                },
              },
            ],
          },
        ],
      }],
      question_types: [],
    };
  },
  methods: {
    getQuestionsTypes() {
      this.http
        .get("marketing-survey-question-types", null, null, true)
        .then((res) => {
          this.question_types = res.data;
        });
    },
    getMarketingSurveyQuestionBySurveyId() {
      this.http
        .post("marketing-survey-parts/by-survey-id", {
          marketing_survey_id: this.$route.params?.id,
        })
        .then((res) => {
          if(res.status){

            this.marketing_survey_parts = res.data;
  
            console.log("items  64:", this.marketing_survey_parts);
          }
        });
    },
    addPart() {
      console.log("step 33");

      this.http.post('marketing-survey-parts',{title: "", marketing_survey_id:this.$route.params.id,}).then(
        (res)=>{
        if(res.status){
    this.getMarketingSurveyQuestionBySurveyId();

        }
      })
      // this.marketing_survey_parts.push({
      //   title: "",
      //   marketing_survey_id:this.$route.params.id,

      //   marketing_survey_questions_groups: [
      //     {
      //       name: "",
      //       unit: "",
      //       alignment: "v",
      //       multiple_answers: true,
      //       answer_count: 0,
      //       marketing_survey_questions: [
      //         {
      //                           marketing_survey_id:this.$route.params.id,
      //           question: "",
      //           marketing_survey_question_options: [
      //             {
      //               option: "",
      //             },
      //           ],
      //           marketing_survey_question_type: {
      //             code: "number",
      //           },
      //         },
      //       ],
      //     },
      //   ],
      // });
   
   },
   deletePart(part){

      this.popup.confirm({
        title:"tree.delete",
        msg:"messages.alert.are_you_sure"
      }).then(resp=>{
        console.log(resp);
        
            if(resp){
              this.http.delete("marketing-survey-parts", part.id).then((res)=>{
                console.log("step 119:", res);
                
                if(res.status){
                    
                    this.getMarketingSurveyQuestionBySurveyId();
                }
              })
            }
          })

   },
   changeSurveyPartTitle(part){
    this.http.put('marketing-survey-parts',part.id,part).then((res)=>{
      if(res.status){
        this.getMarketingSurveyQuestionBySurveyId();

      }
    })
   },
    addGroup(part) {

            this.http.post('marketing-survey-questions-groups',{marketing_survey_part_id:part.id,name: "", unit: "",multiple_answers:false,answer_count: 1}).then(
        (res)=>{
        if(res.status){
    this.getMarketingSurveyQuestionBySurveyId();

        }
      })
      // group.push({
      //   name: "",
      //   unit: "",
      //   alignment: "v",
      //   multiple_answers: true,
      //   answer_count: 0,

      //   marketing_survey_questions: [
      //     {
      //                       marketing_survey_id:this.$route.params.id,
      //       question: "",
      //       marketing_survey_question_options: [
      //         {
      //           option: "",
      //         },
      //       ],
      //       marketing_survey_question_type: {
      //         code: "number",
      //       },
      //     },
      //   ],
      // });
   
   },
   deleteGroup(group){

      this.popup.confirm({
        title:"tree.delete",
        msg:"messages.alert.are_you_sure"
      }).then(resp=>{
        console.log(resp);
        
            if(resp){
              this.http.delete("marketing-survey-questions-groups", group.id).then((res)=>{
                console.log("step 119:", res);
                
                if(res.status){
                    
                    this.getMarketingSurveyQuestionBySurveyId();
                }
              })
            }
          })

   },
   changeGroup(group){
    console.log("step 193 :",group);
    
this.http.put('marketing-survey-questions-groups',group.id,group).then((res)=>{
      if(res.status){
        this.getMarketingSurveyQuestionBySurveyId();

      }
    })
   },
   addQuestion(group) {

            this.http.post('marketing-survey-questions',{question: "",marketing_survey_question_type_id:1, marketing_survey_id:this.$route.params.id,marketing_survey_questions_group_id :group.id}).then(
        (res)=>{
        if(res.status){
    this.getMarketingSurveyQuestionBySurveyId();

        }
      })
      
   },
   deleteQuestion(question){

      this.popup.confirm({
        title:"tree.delete",
        msg:"messages.alert.are_you_sure"
      }).then(resp=>{
        console.log(resp);
        
            if(resp){
              this.http.delete("marketing-survey-questions", question.id).then((res)=>{
                console.log("step 119:", res);
                
                if(res.status){
                    
                    this.getMarketingSurveyQuestionBySurveyId();
                }
              })
            }
          })

   },
   changeQuestion(question){
    this.http.put('marketing-survey-questions',question.id,question).then((res)=>{
      if(res.status){
        this.getMarketingSurveyQuestionBySurveyId();

      }
    })
   },
    addOptions(question) {
            this.http.post('marketing-survey-question-options',{option: "",marketing_survey_question_id:question.id}).then(
        (res)=>{
        if(res.status){
    this.getMarketingSurveyQuestionBySurveyId();

        }
      })
      
    
    },
    deleteOption(option){

      this.popup.confirm({
        title:"tree.delete",
        msg:"messages.alert.are_you_sure"
      }).then(resp=>{
        console.log(resp);
        
            if(resp){
              this.http.delete("marketing-survey-question-options", option.id).then((res)=>{
                console.log("step 119:", res);
                
                if(res.status){
                    
                    this.getMarketingSurveyQuestionBySurveyId();
                }
              })
            }
          })

   },
   changeOption(option){
    this.http.put('marketing-survey-question-options',option.id,option).then((res)=>{
      if(res.status){
        this.getMarketingSurveyQuestionBySurveyId();

      }
    })
   },

    submitSurvey(){
      console.log('step 113 :',this.marketing_survey_parts)
      this.http.post('marketing-survey-parts',{survey_parts:this.marketing_survey_parts,marketing_survey_id:this.$route.params.id}).then((res)=>{
        if(res.status){
          Swal.fire(this.$t('popups.success'),this.$t('popups.ok')).then(()=>{
          });

        }
      })
    }
  },
  mounted() {
    this.getMarketingSurveyQuestionBySurveyId();
    this.getQuestionsTypes();
  },
};
</script>

<template>
  

  <div class="row mb-3">
    <div class="col-12">
      <h2>{{        $t("marketing.add_question.part_setup")
}}</h2>
    </div>
    <div class="col-4">
      <div class="d-flex justify-content-start">
        <button
          type="button"
          class="btn btn-light float-end"
          @click="addPart()"
        >
          <span
            class="bx bxs-plus-circle float-end fa-2x"
            style="color: #2a3042 !important"
          ></span>
        </button>
      </div>
    </div>
  </div>
  <fieldset
    class="row mb-3"
    v-for="(part, index) in marketing_survey_parts"
    :key="index"
  >
    <legend>{{$t('marketing.add_question.question_part') + '  '+ parseInt(index+1) }}</legend>

  <div class="row justify-content-end">

    <div class="col-4">
      <div class="d-flex justify-content-end">
        <button
          :disabled="marketing_survey_parts.length == 1"
          type="button"
          class="btn float-end"
          @click="deletePart(part)"
        >
          <span class="bx bx-trash text-danger float-end fa-2x"></span>
        </button>
      </div>
    </div>
  </div>
    <div class="col-12 mb-5">
      <label class="form-label">{{
        $t("marketing.add_question.part_title")
      }}</label>
      <input  v-model="part.title" @input="changeSurveyPartTitle(part)" type="text" class="form-control" />
    </div>
    <div class="col-12 mb-2">
     <legend class="fw-bold">
      
      {{$t('marketing.add_question.question_group_settings')}}
      </legend> 
    </div>
    <div class="col-4 mb-3">
      <div class="d-flex justify-content-start">
        <button
          type="button"
          class="btn btn-light float-end"
          @click="addGroup(part)"
        >
          <span
            class="bx bxs-plus-circle float-end fa-2x"
            style="color: #2a3042 !important"
          ></span>
        </button>
      </div>
    </div>
    
    <fieldset
      class="row mb-3 overflow-scroll"
      v-for="(group, group_index) in part.marketing_survey_questions_groups"
      :key="group_index"
    > 
        <legend>{{$t('marketing.add_question.question_group')  + '   '+ parseInt(group_index + 1)  }} </legend>

      <div class="row justify-content-end">

      <div class="col-4">
        <div class="d-flex justify-content-end">
          <button
            :disabled="part.marketing_survey_questions_groups.length == 1"
            type="button"
            class="btn float-end"
            @click="deleteGroup(group)
            "
          >
            <span class="bx bx-trash text-danger float-end fa-2x"></span>
          </button>
        </div>
      </div>
      </div>
      <div class="col-12 mb-3">
        <label class="form-label">{{
          $t("marketing.add_question.group_title")
        }}</label>
        <input @input="changeGroup(group)" v-model="group.name" type="text" class="form-control" />
      </div>
      
      <div class="col-md-6 mb-3">
        <div class="row">
          <label class="form-label">{{
            $t("marketing.add_question.multiple_answers")
          }}</label>
        </div>
        <div class="form-check form-check-inline">
          <input
          required
          @change="changeGroup(group)"
            class="form-check-input"
            type="radio"
            v-model="group.multiple_answers"
            :value="true"
          />
          <label class="form-check-label" for="">{{
            $t("marketing.add_question.yes")
          }}</label>
        </div>
        <div class="form-check form-check-inline">
          <input
          @change="changeGroup(group)"
            required
            class="form-check-input"
            type="radio"
            v-model="group.multiple_answers"
            :value="false"
          />
          <label class="form-check-label" for="">{{
            $t("marketing.add_question.no")
          }}</label>
        </div>
      </div>
      <div class="col-md-6 mb-3" v-if="group?.marketing_survey_questions.some(q =>q.marketing_survey_question_type_id != this.question_types.find(type => type.code=='text')?.id && q.marketing_survey_question_type_id != this.question_types.find(type => type.code=='number')?.id && q.marketing_survey_question_type_id != this.question_types.find(type => type.code=='check_box')?.id && q.marketing_survey_question_type_id != this.question_types.find(type => type.code=='select')?.id)">
        <div class="row">
          <label class="form-label">{{
            $t("marketing.add_question.has_other")
          }}</label>
        </div>
        <div class="form-check form-check-inline">
          <input
          required
          @change="changeGroup(group)"
            class="form-check-input"
            type="radio"
            v-model="group.has_other"
            :value="true"
          />
          <label class="form-check-label" for="">{{
            $t("marketing.add_question.yes")
          }}</label>
        </div>
        <div class="form-check form-check-inline">
          <input
          @change="changeGroup(group)"
            required
            class="form-check-input"
            type="radio"
            v-model="group.has_other"
            :value="false"
          />
          <label class="form-check-label" for="">{{
            $t("marketing.add_question.no")
          }}</label>
        </div>
      </div>
      <div v-if="group.multiple_answers" class="col-md-6 mb-3">
        <label class="form-label">{{
          $t("marketing.add_question.answer_count")
        }}</label>
        <input
        @input="changeGroup(group)"
        required
          v-model="group.answer_count"
          type="number"
          class="form-control"
        />
      </div>
      <div  class="col-md-6 mb-5">
        <label class="form-label">{{
          $t("marketing.add_question.unit")
        }}</label>
        <input
        @input="changeGroup(group)"
          v-model="group.unit"
          type="text"
          class="form-control"
        />
      </div>
    <div class="row mb-3">
<div class="col-12 mb-2">
     <legend class="fw-bold">
      
      {{$t('marketing.add_question.question_setup')}}
      </legend> 
    </div>
    </div>
      <div class="row">
        <div class="col-4">
          <div class="d-flex justify-content-start">
            <button
              type="button"
              class="btn float-end"
              @click="addQuestion(group)"
            >
              <span
                class="bx bxs-plus-circle float-end fa-2x"
                style="color: #2a3042 !important"
              ></span>
            </button>
          </div>
        </div>
      </div>
      
      <fieldset
        v-for="(question, index) in group?.marketing_survey_questions"
        :key="index"
        class="row mb-3 "
      >
              <legend>{{$t('marketing.add_question.question')+ '  '+ parseInt(index + 1)}} </legend>

        <div class="row mb-3 justify-content-end">
          <div class="col-4">
            <div class="d-flex justify-content-end">
              <button
                type="button"
                class="btn float-end"
                @click="deleteQuestion(question)"
                :disabled="group?.marketing_survey_questions.length == 1"
              >
                <span class="bx bx-trash text-danger float-end fa-2x"></span>
              </button>
            </div>
          </div>
        </div>
        <div class="col-md-6 mb-3">
          <label for="" class="form-label">
            {{ $t("marketing.add_question.question_type") }}
          </label>
          <select
          required
          @change="changeQuestion(question)"
            v-model="question.marketing_survey_question_type_id"
            class="form-select"
            aria-label=".form-select-sm example"
          >
            <option
              v-for="(type, index) in question_types.filter((type)=>{
                if(group.multiple_answers){
                  return type.code =='text' || type.code =='number'|| type.code =='select'
                }
                else if(group.has_other){
                  return type.code =='text' || type.code =='number' || type.code =='multiple_choices' || type.code =='radio'
                }
                else{
                  return type
                }
              })"
              :value="type.id"
              :key="index"
            >
              {{ $i18n.locale == "en" ? type.name_en : type.name }}
            </option>
          </select>
        </div>
        <div class="col-md-6 mb-3">
          <label class="form-label">{{
            $t("marketing.add_question.question")
          }}</label>
          <input required @input="changeQuestion(question)" v-model="question.question" type="text" class="form-control" />
        </div>

        <div  v-if="question?.marketing_survey_question_type?.code =='radio'|| question?.marketing_survey_question_type?.code =='multiple_choices'|| question?.marketing_survey_question_type?.code =='select'" class="row mb-3">
          <div class="col-2">

                <legend>{{$t('marketing.add_question.question_options')}}</legend>
          </div>
          <div  class="col-4">
            <div class="d-flex justify-content-start">
              <button
                type="button"
                class="btn btn-light float-end"
                @click="addOptions(question)"
              >
                <span
                  class="bx bxs-plus-circle float-end fa-2x"
                  style="color: #2a3042 !important"
                ></span>
              </button>
            </div>
          </div>
        </div>
        <template v-if="question?.marketing_survey_question_type?.code =='radio'|| question?.marketing_survey_question_type?.code =='multiple_choices'|| question?.marketing_survey_question_type?.code =='select'" >
          
            
          
        <fieldset  class="row mb-3" v-for="(option, index) in question?.marketing_survey_question_options.filter(option => !option.is_other)" :key="index">
<div class="row justify-content-end mb-3 ">

          <div class="col-4">
            <div class="d-flex justify-content-end">
              <button
                type="button"
                class="btn float-end"
                @click="deleteOption(option)"
                :disabled="question?.marketing_survey_question_options.length == 1"
              >
                <span class="bx bx-trash text-danger float-end fa-2x"></span>
              </button>
            </div>
          </div>
        </div>
<div class="col-12 mb-3">
      <label class="form-label">{{
        $t("marketing.add_question.question_option")
      }}</label>
      <input required @input="changeOption(option)" v-model="option.option" type="text" class="form-control" />
    </div>
        </fieldset>
        </template>
      </fieldset>
    </fieldset>
  </fieldset>



  <!-- end  table -->

  <!--   Apps  Pagination     -->

  <!--end-->
</template>

<style scoped>
fieldset {
  border: 1px solid #ddd !important;
  margin: 0;
  padding: 10px;
  position: relative;
  border-radius: 4px;
  background-color: #f5f5f5;
  padding-left: 10px !important;
}
/* 	
		legend
		{
			font-size:14px;
			font-weight:bold;
			margin-bottom: 0px; 
			width: 35%; 
			border: 1px solid #ddd;
			border-radius: 4px; 
			padding: 5px 5px 5px 10px; 
			background-color: #ffffff;
		} */
</style>
