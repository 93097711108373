<script>
import appConfig from "@/app.config";
import { Modal } from "bootstrap";
export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      data: {
        marketing_area_id:'',
        marketing_district_id: "",
      },
      ArchiveFiles: [],
      archive_files_types: [],
      marketingAreas: [],
      page: 1,
      limit: 20,
      tot_pages: 0,
      searchArchiveFiles: null,
      ArchiveFilesSearchMode: false,
      searchModel: "",
      marketingDistricts: [],
      add_survey_area_modal: {},
    };
  },
  methods: {
 
    addArea() {
      this.http
        .post(
          "marketing-survey-areas",
          { ...this.data, marketing_survey_id: this.$route.params?.id },
          null,
          true
        )
        .then((res) => {
          if(res.status){
            this.add_survey_area_modal.hide()
            this.get(this.page);
          }
        });
    },
    search() {
      
      this.ArchiveFilesSearchMode = true;
      this.tot_pages = 0;
      this.http
        .post(
          "marketing-survey-areas/by-survey-id/search",
          {
            search: this.searchModel,
            limit: this.limit,
            page: this.page,
            col: "name",
            marketing_survey_id: this.$route.params?.id,
          },
          null,
          true
        )
        .then((res) => {
          this.ArchiveFiles = res.data;
          console.log("######## files", this.ArchiveFiles);
        });
    },
    cancelappsearchMode() {
      this.searchModel = "";
      this.ArchiveFilesSearchMode = false;
      this.get(this.page);
    },
    getMarketingAreas() {
      this.http.get("marketing-areas", null, null, true).then((res) => {
        this.marketingAreas = res.data;
      });
    },
    getArchiveFiles() {
      this.http
        .post(
          "marketing-survey-areas/by-survey-id",
          {
            marketing_survey_id: this.$route.params?.id,
          },
          null,
          true
        )
        .then((res) => {
          this.ArchiveFiles = res.data;
        });
    },
    deleteArchiveFiles(app) {
      var data = {
        title: "popups.deletefile",
        msg: "popups.delmessage",
      };
      this.popup.confirm(data).then((resp) => {
        if (resp) {
          console.log("###");
          this.http.delete("marketing-survey-areas", app.id).then(() => {
            console.log("############################3");
            this.get(this.page);
            this.getMarketingAreas();
          });
        }
      });
    },
    navigate(area) {
      console.log({ area });
      this.$router.push({
        name: "surveyArea",
        params: { surveyId: this.$route.params?.id, areaId: area?.marketing_area_id },
      });
    },
    get(page) {
      console.log(page);
      this.http
        .post(
          "marketing-survey-areas/by-survey-id/pagination",
          {
            limit: this.limit,
            page: page,
            marketing_survey_id: this.$route.params?.id,
          },
          null,
          true
        )
        .then((res) => {
          this.page = page;
          this.tot_pages = Math.ceil(res.tot / this.limit);
          console.log({ returnedData: res.data });
          this.ArchiveFiles = res.data;
        });
    },
    getMarketingDistricts() {
      this.http.get("marketing-districts", null, null, true).then((res) => {
        this.marketingDistricts = res.data;
      });
    },
  },
  created() {
    this.get(1);
    this.getMarketingDistricts();
    this.getMarketingAreas();
  },
  mounted() {
    this.add_survey_area_modal = new Modal("#addSurveyArea");
  },
};
</script>

<template>
  <div class="row align-items-center">
    <div class="col-4"></div>

    <div class="col-8">
      <div
        class="search-box chat-search-box d-flex align-items-center"
        style="width: 250px; margin-inline-start: auto"
      >
        <div class="position-relative mx-3 d-flex align-items-center">
          <input
            @keyup="search()"
            type="text"
            class="form-control fa-lg text-light"
            :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
            :placeholder="$t('users.searchplaceholder')"
            v-model="searchModel"
            style="background-color: #2a3042 !important"
          />
          <i class="bx bx-search-alt search-icon text-light"></i>
          <button
            @click="cancelappsearchMode()"
            v-if="searchModel"
            class="btn-close text-light"
            style="position: absolute; top: 12px; left: 250px"
          ></button>
        </div>
        <button
          type="button"
          class="btn btn-light float-end"
          @click="
            data = { marketing_area_id: '', marketing_district_id: '' };
            add_survey_area_modal.show();
          "
        >
          <span
            class="bx bxs-plus-circle float-end fa-2x"
            style="color: #2a3042 !important"
          ></span>
        </button>
      </div>
    </div>
  </div>
  <!-- end row -->

  <hr class="mb-4" />

  <div class="row">
    <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table">
      <thead>
        <tr
          class="text-light text-center"
          style="background-color: #2a3042 !important"
        >
         
          <th scope="col">
            {{ $t("marketing.team_member.interviewers.table.district") }}
          </th>
          <th scope="col">
            {{ $t("marketing.team_member.interviewers.table.area") }}
          </th>

          <th scope="col">
            {{ $t("marketing.survey_list.table.team_member_details") }}
          </th>
          <th scope="col">
            {{ $t("archiving_documents.archiving_documents_table.operations") }}
          </th>
          "
        </tr>
      </thead>
      <tbody>
        <tr v-for="(app) in ArchiveFiles" :key="app" class="text-center">
     
          <td>{{ app?.marketing_area?.marketing_district?.name }}</td>
          <td>{{ app?.marketing_area?.name }}</td>

          <td>
            <button @click="navigate(app)" class="btn btn-primary btn-sm me-2">
              {{$t('marketing.change_team_members_info')}}
            </button>
          </td>
          <td class="d-flex justify-content-end">
            <a
              class="btn btn-danger"
              href="javascript: void(0);"
              role="button"
              @click="deleteArchiveFiles(app)"
              >{{ $t("popups.delete") }}</a
            >
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <!-- Button trigger modal -->
  >

  <!-- Modal -->
  <div
    class="modal fade"
    id="addSurveyArea"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modelTitleId"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t("popups.add") }}</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form @submit.prevent="addArea()">
          <div class="modal-body">
            <div class="mb-3">
              <label for="" class="form-label">{{
                $t("marketing.districts")
              }}</label>
              <select
                @change="data.marketing_area_id=''"
                v-model="data.marketing_district_id"
                class="form-select form-select"
                name=""
                id=""
              >
                <option
                  v-for="a in marketingDistricts"
                  :key="a.id"
                  :value="a.id"
                >
                  {{ a.name }}
                </option>
              </select>
            </div>
            <div class="mb-3">
              <label for="" class="form-label">{{
                $t("marketing.team_member.tabs.areas")
              }}</label>

              <select
                v-model="data.marketing_area_id"
                class="form-select mb-2"
                id="area"
              >
                <option
                  v-for="a in marketingAreas.filter(
                    (aa) =>
                      aa.marketing_district_id == data.marketing_district_id
                  )"
                  :key="a.id"
                  :value="a.id"
                >
                  {{ a.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              {{ $t("popups.close") }}
            </button>
            <button
              :disabled="
                !(data.marketing_district_id && data.marketing_area_id)
              "
              type="submit"
              class="btn btn-primary"
            >
              {{ $t("popups.add") }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- end  table -->
  <!-- Button trigger modal -->

  <div v-if="tot_pages > 0">
    <!--   Apps  Pagination     -->
    <ul class="pagination pagination-rounded justify-content-center mb-2">
      <li class="page-item" :class="{ disabled: page == 1 }">
        <a
          class="page-link"
          href="javascript: void(0);"
          @click="get(page - 1)"
          aria-label="Previous"
        >
          <i class="mdi mdi-chevron-left"></i>
        </a>
      </li>
      <li
        class="page-item"
        :class="{ active: p == page }"
        v-for="p in tot_pages"
        :key="p"
      >
        <a class="page-link" href="javascript: void(0);" @click="get(p)">{{
          p
        }}</a>
      </li>

      <li class="page-item" :class="{ disabled: page == tot_pages }">
        <a
          class="page-link"
          href="javascript: void(0);"
          @click="get(page + 1)"
          aria-label="Next"
        >
          <i class="mdi mdi-chevron-right"></i>
        </a>
      </li>
    </ul>
  </div>
  <!--end-->
</template>

<style scoped>
.btn-close {
  position: relative;
  right: 317px;
  top: 6px;
  font-size: 15px;
}
</style>
